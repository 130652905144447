:root {
  --aa-search-input-height: 44px;
  --aa-input-icon-size: 20px;
  --aa-base-unit: 16;
  --aa-spacing-factor: 1;
  --aa-spacing: calc(var(--aa-base-unit) * var(--aa-spacing-factor) * 1px);
  --aa-spacing-half: calc(var(--aa-spacing) / 2);
  --aa-panel-max-height: 650px;
  --aa-base-z-index: 9999;
  --aa-font-size: calc(var(--aa-base-unit) * 1px);
  --aa-font-family: inherit;
  --aa-font-weight-medium: 500;
  --aa-font-weight-semibold: 600;
  --aa-font-weight-bold: 700;
  --aa-icon-size: 20px;
  --aa-icon-stroke-width: 1.6;
  --aa-icon-color-rgb: 119, 119, 163;
  --aa-icon-color-alpha: 1;
  --aa-action-icon-size: 20px;
  --aa-text-color-rgb: 38, 38, 39;
  --aa-text-color-alpha: 1;
  --aa-primary-color-rgb: 62, 52, 211;
  --aa-primary-color-alpha: .2;
  --aa-muted-color-rgb: 128, 126, 163;
  --aa-muted-color-alpha: .6;
  --aa-panel-border-color-rgb: 128, 126, 163;
  --aa-panel-border-color-alpha: .3;
  --aa-input-border-color-rgb: 128, 126, 163;
  --aa-input-border-color-alpha: .8;
  --aa-background-color-rgb: 255, 255, 255;
  --aa-background-color-alpha: 1;
  --aa-input-background-color-rgb: 255, 255, 255;
  --aa-input-background-color-alpha: 1;
  --aa-selected-color-rgb: 179, 173, 214;
  --aa-selected-color-alpha: .205;
  --aa-description-highlight-background-color-rgb: 245, 223, 77;
  --aa-description-highlight-background-color-alpha: .5;
  --aa-detached-media-query: (max-width:680px);
  --aa-detached-modal-media-query: (min-width:680px);
  --aa-detached-modal-max-width: 680px;
  --aa-detached-modal-max-height: 500px;
  --aa-overlay-color-rgb: 115, 114, 129;
  --aa-overlay-color-alpha: .4;
  --aa-panel-shadow: 0 0 0 1px #23263b1a, 0 6px 16px -4px #23263b26;
  --aa-scrollbar-width: 13px;
  --aa-scrollbar-track-background-color-rgb: 234, 234, 234;
  --aa-scrollbar-track-background-color-alpha: 1;
  --aa-scrollbar-thumb-background-color-rgb: var(--aa-background-color-rgb);
  --aa-scrollbar-thumb-background-color-alpha: 1;
}

@media (hover: none) and (pointer: coarse) {
  :root {
    --aa-spacing-factor: 1.2;
    --aa-action-icon-size: 22px;
  }
}

body.dark, body[data-theme="dark"] {
  --aa-text-color-rgb: 183, 192, 199;
  --aa-primary-color-rgb: 146, 138, 255;
  --aa-muted-color-rgb: 146, 138, 255;
  --aa-input-background-color-rgb: 0, 3, 9;
  --aa-background-color-rgb: 21, 24, 42;
  --aa-selected-color-rgb: 146, 138, 255;
  --aa-selected-color-alpha: .25;
  --aa-description-highlight-background-color-rgb: 0 255 255;
  --aa-description-highlight-background-color-alpha: .25;
  --aa-icon-color-rgb: 119, 119, 163;
  --aa-panel-shadow: inset 1px 1px 0 0 #2c2e40, 0 3px 8px 0 #000309;
  --aa-scrollbar-track-background-color-rgb: 44, 46, 64;
  --aa-scrollbar-thumb-background-color-rgb: var(--aa-background-color-rgb);
}

.aa-Autocomplete *, .aa-DetachedFormContainer *, .aa-Panel * {
  box-sizing: border-box;
}

.aa-Autocomplete, .aa-DetachedFormContainer, .aa-Panel {
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font-family: inherit;
  font-family: var(--aa-font-family);
  font-size: 16px;
  font-size: var(--aa-font-size);
  text-align: left;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1em;
}

.aa-Form {
  background-color: #fff;
  background-color: rgba(var(--aa-input-background-color-rgb), var(--aa-input-background-color-alpha));
  border: 1px solid #807ea3cc;
  border: 1px solid rgba(var(--aa-input-border-color-rgb), var(--aa-input-border-color-alpha));
  width: 100%;
  border-radius: 3px;
  align-items: center;
  margin: 0;
  line-height: 1em;
  display: flex;
  position: relative;
}

.aa-Form[focus-within], .aa-Form:focus-within {
  border-color: #3e34d3;
  border-color: rgba(var(--aa-primary-color-rgb), 1);
  box-shadow: 0 0 0 2px #3e34d333, inset 0 0 0 2px #3e34d333;
  box-shadow: rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px, inset rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px;
  outline: none;
}

.aa-InputWrapperPrefix {
  height: 44px;
  height: var(--aa-search-input-height);
  flex-shrink: 0;
  order: 1;
  align-items: center;
  display: flex;
}

.aa-Label, .aa-LoadingIndicator {
  cursor: auto;
  height: 100%;
  text-align: left;
  flex-shrink: 0;
  padding: 0;
}

.aa-Label svg, .aa-LoadingIndicator svg {
  color: #3e34d3;
  color: rgba(var(--aa-primary-color-rgb), 1);
  height: auto;
  max-height: 20px;
  max-height: var(--aa-input-icon-size);
  stroke-width: 1.6px;
  stroke-width: var(--aa-icon-stroke-width);
  width: 20px;
  width: var(--aa-input-icon-size);
}

.aa-LoadingIndicator, .aa-SubmitButton {
  height: 100%;
  padding-left: 11px;
  padding-left: calc(var(--aa-spacing) * .75 - 1px);
  padding-right: 8px;
  padding-right: var(--aa-spacing-half);
  width: 47px;
  width: calc(var(--aa-spacing) * 1.75 + var(--aa-icon-size)  - 1px);
}

@media (hover: none) and (pointer: coarse) {
  .aa-LoadingIndicator, .aa-SubmitButton {
    padding-left: 3px;
    padding-left: calc(var(--aa-spacing-half) / 2 - 1px);
    width: 39px;
    width: calc(var(--aa-icon-size)  + var(--aa-spacing) * 1.25 - 1px);
  }
}

.aa-SubmitButton {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
}

.aa-LoadingIndicator {
  justify-content: center;
  align-items: center;
  display: flex;
}

.aa-LoadingIndicator[hidden] {
  display: none;
}

.aa-InputWrapper {
  width: 100%;
  order: 3;
  position: relative;
}

.aa-Input {
  -webkit-appearance: none;
  appearance: none;
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font: inherit;
  height: 44px;
  height: var(--aa-search-input-height);
  width: 100%;
  background: none;
  border: 0;
  padding: 0;
}

.aa-Input::placeholder {
  color: #807ea399;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  opacity: 1;
}

.aa-Input:focus {
  border-color: none;
  box-shadow: none;
  outline: none;
}

.aa-Input::-webkit-search-cancel-button, .aa-Input::-webkit-search-decoration, .aa-Input::-webkit-search-results-button, .aa-Input::-webkit-search-results-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.aa-InputWrapperSuffix {
  height: 44px;
  height: var(--aa-search-input-height);
  order: 4;
  align-items: center;
  display: flex;
}

.aa-ClearButton {
  color: #807ea399;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  cursor: pointer;
  height: 100%;
  padding: 0 12.8333px;
  padding: 0 calc(var(--aa-spacing) * .83333 - .5px);
  background: none;
  border: 0;
  align-items: center;
  margin: 0;
  display: flex;
}

@media (hover: none) and (pointer: coarse) {
  .aa-ClearButton {
    padding: 0 10.1667px;
    padding: 0 calc(var(--aa-spacing) * .66667 - .5px);
  }
}

.aa-ClearButton:focus, .aa-ClearButton:hover {
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
}

.aa-ClearButton[hidden] {
  display: none;
}

.aa-ClearButton svg {
  stroke-width: 1.6px;
  stroke-width: var(--aa-icon-stroke-width);
  width: 20px;
  width: var(--aa-icon-size);
}

.aa-Panel {
  background-color: #fff;
  background-color: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  border-radius: 4px;
  border-radius: calc(var(--aa-spacing) / 4);
  box-shadow: 0 0 0 1px #23263b1a, 0 6px 16px -4px #23263b26;
  box-shadow: var(--aa-panel-shadow);
  margin: 8px 0 0;
  transition: opacity .2s ease-in, filter .2s ease-in;
  position: absolute;
  overflow: hidden;
}

@media screen and (prefers-reduced-motion) {
  .aa-Panel {
    transition: none;
  }
}

.aa-Panel button {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.aa-PanelLayout {
  height: 100%;
  max-height: 650px;
  max-height: var(--aa-panel-max-height);
  text-align: left;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: auto;
}

.aa-PanelLayoutColumns--twoGolden {
  grid-template-columns: 39.2% auto;
  padding: 0;
  display: grid;
  overflow: hidden;
}

.aa-PanelLayoutColumns--two {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 0;
  display: grid;
  overflow: hidden;
}

.aa-PanelLayoutColumns--three {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 0;
  display: grid;
  overflow: hidden;
}

.aa-Panel--stalled .aa-Source {
  filter: grayscale();
  opacity: .8;
}

.aa-Panel--scrollable {
  max-height: 650px;
  max-height: var(--aa-panel-max-height);
  padding: 8px;
  padding: var(--aa-spacing-half);
  scrollbar-color: #fff #eaeaea;
  scrollbar-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb), var(--aa-scrollbar-thumb-background-color-alpha)) rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
  scrollbar-width: thin;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.aa-Panel--scrollable::-webkit-scrollbar {
  width: 13px;
  width: var(--aa-scrollbar-width);
}

.aa-Panel--scrollable::-webkit-scrollbar-track {
  background-color: #eaeaea;
  background-color: rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
}

.aa-Panel--scrollable::-webkit-scrollbar-thumb {
  background-color: #fff;
  background-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb), var(--aa-scrollbar-thumb-background-color-alpha));
  border: 3px solid #eaeaea;
  border-color: rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
  border-right: 2px solid rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
  border-radius: 9999px;
}

.aa-Source {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.aa-Source:empty {
  display: none;
}

.aa-SourceNoResults {
  padding: 16px;
  padding: var(--aa-spacing);
  margin: 0;
  font-size: 1em;
}

.aa-List {
  margin: 0;
  list-style: none;
}

.aa-List, .aa-SourceHeader {
  padding: 0;
  position: relative;
}

.aa-SourceHeader {
  margin: 8px .5em 8px 0;
  margin: var(--aa-spacing-half) .5em var(--aa-spacing-half) 0;
}

.aa-SourceHeader:empty {
  display: none;
}

.aa-SourceHeaderTitle {
  background: #fff;
  background: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  color: #3e34d3;
  color: rgba(var(--aa-primary-color-rgb), 1);
  font-size: .8em;
  font-weight: 600;
  font-weight: var(--aa-font-weight-semibold);
  padding: 0 8px 0 0;
  padding: 0 var(--aa-spacing-half) 0 0;
  z-index: 9999;
  z-index: var(--aa-base-z-index);
  margin: 0;
  display: inline-block;
  position: relative;
}

.aa-SourceHeaderLine {
  border-bottom: 1px solid #3e34d3;
  border-bottom: 1px solid rgba(var(--aa-primary-color-rgb), 1);
  height: 2px;
  opacity: .3;
  top: 8px;
  left: 0;
  right: 0;
  top: var(--aa-spacing-half);
  z-index: 9998;
  z-index: calc(var(--aa-base-z-index)  - 1);
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
}

.aa-SourceFooterSeeAll {
  background: linear-gradient(#fff, #807ea324);
  background: linear-gradient(180deg, rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha)), #807ea324);
  border: 1px solid #807ea399;
  border: 1px solid rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  color: inherit;
  font-size: .95em;
  font-weight: 500;
  font-weight: var(--aa-font-weight-medium);
  border-radius: 5px;
  padding: .475em 1em .6em;
  text-decoration: none;
  box-shadow: inset 0 0 2px #fff, 0 2px 2px -1px #4c455826;
}

.aa-SourceFooterSeeAll:focus, .aa-SourceFooterSeeAll:hover {
  border: 1px solid #3e34d3;
  border: 1px solid rgba(var(--aa-primary-color-rgb), 1);
  color: #3e34d3;
  color: rgba(var(--aa-primary-color-rgb), 1);
}

.aa-Item {
  cursor: pointer;
  min-height: 40px;
  min-height: calc(var(--aa-spacing) * 2.5);
  padding: 4px;
  padding: calc(var(--aa-spacing-half) / 2);
  border-radius: 3px;
  align-items: center;
  display: grid;
}

.aa-Item[aria-selected="true"] {
  background-color: #b3add634;
  background-color: rgba(var(--aa-selected-color-rgb), var(--aa-selected-color-alpha));
}

.aa-Item[aria-selected="true"] .aa-ActiveOnly, .aa-Item[aria-selected="true"] .aa-ItemActionButton {
  visibility: visible;
}

.aa-ItemIcon {
  background: #fff;
  background: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  box-shadow: inset 0 0 0 1px #807ea34d;
  box-shadow: inset 0 0 0 1px rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
  color: #7777a3;
  color: rgba(var(--aa-icon-color-rgb), var(--aa-icon-color-alpha));
  height: 28px;
  height: calc(var(--aa-icon-size)  + var(--aa-spacing-half));
  stroke-width: 1.6px;
  stroke-width: var(--aa-icon-stroke-width);
  text-align: center;
  width: 28px;
  width: calc(var(--aa-icon-size)  + var(--aa-spacing-half));
  border-radius: 3px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: .7em;
  display: flex;
  overflow: hidden;
}

.aa-ItemIcon img {
  height: auto;
  max-height: 20px;
  max-height: calc(var(--aa-icon-size)  + var(--aa-spacing-half)  - 8px);
  max-width: 20px;
  max-width: calc(var(--aa-icon-size)  + var(--aa-spacing-half)  - 8px);
  width: auto;
}

.aa-ItemIcon svg {
  height: 20px;
  height: var(--aa-icon-size);
  width: 20px;
  width: var(--aa-icon-size);
}

.aa-ItemIcon--alignTop {
  align-self: flex-start;
}

.aa-ItemIcon--noBorder {
  box-shadow: none;
  background: none;
}

.aa-ItemIcon--picture {
  height: 96px;
  width: 96px;
}

.aa-ItemIcon--picture img {
  max-height: 100%;
  max-width: 100%;
  padding: 8px;
  padding: var(--aa-spacing-half);
}

.aa-ItemContent {
  cursor: pointer;
  grid-gap: 8px;
  grid-gap: var(--aa-spacing-half);
  align-items: center;
  gap: 8px;
  gap: var(--aa-spacing-half);
  grid-auto-flow: column;
  line-height: 1.25em;
  display: grid;
  overflow: hidden;
}

.aa-ItemContent:empty {
  display: none;
}

.aa-ItemContent mark {
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font-style: normal;
  font-weight: 700;
  font-weight: var(--aa-font-weight-bold);
  background: none;
}

.aa-ItemContent--dual {
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.aa-ItemContent--dual .aa-ItemContentSubtitle, .aa-ItemContent--dual .aa-ItemContentTitle {
  display: block;
}

.aa-ItemContent--indented {
  padding-left: 36px;
  padding-left: calc(var(--aa-icon-size)  + var(--aa-spacing));
}

.aa-ItemContentBody {
  grid-gap: 4px;
  grid-gap: calc(var(--aa-spacing-half) / 2);
  gap: 4px;
  gap: calc(var(--aa-spacing-half) / 2);
  display: grid;
}

.aa-ItemContentTitle {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 .5em 0 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

.aa-ItemContentSubtitle {
  font-size: .92em;
}

.aa-ItemContentSubtitleIcon:before {
  border-color: #807ea3a3;
  border-color: rgba(var(--aa-muted-color-rgb), .64);
  content: "";
  border-style: solid;
  display: inline-block;
  position: relative;
  top: -3px;
  left: 1px;
}

.aa-ItemContentSubtitle--inline .aa-ItemContentSubtitleIcon:before {
  margin-left: 8px;
  margin-left: var(--aa-spacing-half);
  margin-right: 4px;
  margin-right: calc(var(--aa-spacing-half) / 2);
  width: 10px;
  width: calc(var(--aa-spacing-half)  + 2px);
  border-width: 0 0 1.5px;
}

.aa-ItemContentSubtitle--standalone {
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  grid-gap: 8px;
  grid-gap: var(--aa-spacing-half);
  align-items: center;
  gap: 8px;
  gap: var(--aa-spacing-half);
  grid-auto-flow: column;
  justify-content: start;
  display: grid;
}

.aa-ItemContentSubtitle--standalone .aa-ItemContentSubtitleIcon:before {
  height: 8px;
  height: var(--aa-spacing-half);
  width: 8px;
  width: var(--aa-spacing-half);
  border-width: 0 0 1.5px 1.5px;
  border-radius: 0 0 0 3px;
}

.aa-ItemContentSubtitleCategory {
  color: #807ea3;
  color: rgba(var(--aa-muted-color-rgb), 1);
  font-weight: 500;
}

.aa-ItemContentDescription {
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: .85em;
  overflow-x: hidden;
}

.aa-ItemContentDescription:empty {
  display: none;
}

.aa-ItemContentDescription mark {
  background: #f5df4d80;
  background: rgba(var(--aa-description-highlight-background-color-rgb), var(--aa-description-highlight-background-color-alpha));
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  font-style: normal;
  font-weight: 500;
  font-weight: var(--aa-font-weight-medium);
}

.aa-ItemContentDash {
  color: #807ea399;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  opacity: .4;
  display: none;
}

.aa-ItemContentTag {
  background-color: #3e34d333;
  background-color: rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha));
  border-radius: 3px;
  margin: 0 .4em 0 0;
  padding: .08em .3em;
}

.aa-ItemLink, .aa-ItemWrapper {
  color: inherit;
  grid-gap: 4px;
  grid-gap: calc(var(--aa-spacing-half) / 2);
  align-items: center;
  gap: 4px;
  gap: calc(var(--aa-spacing-half) / 2);
  width: 100%;
  grid-auto-flow: column;
  justify-content: space-between;
  display: grid;
}

.aa-ItemLink {
  color: inherit;
  text-decoration: none;
}

.aa-ItemActions {
  height: 100%;
  margin: 0 -5.33333px;
  margin: 0 calc(var(--aa-spacing) / -3);
  grid-auto-flow: column;
  justify-self: end;
  padding: 0 2px 0 0;
  display: grid;
}

.aa-ItemActionButton {
  color: #807ea399;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  cursor: pointer;
  background: none;
  border: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  display: flex;
}

.aa-ItemActionButton:focus svg, .aa-ItemActionButton:hover svg {
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
}

@media (hover: none) and (pointer: coarse) {
  .aa-ItemActionButton:focus svg, .aa-ItemActionButton:hover svg {
    color: inherit;
  }
}

.aa-ItemActionButton svg {
  color: #807ea399;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  margin: 5.33333px;
  margin: calc(var(--aa-spacing) / 3);
  stroke-width: 1.6px;
  stroke-width: var(--aa-icon-stroke-width);
  width: 20px;
  width: var(--aa-action-icon-size);
}

.aa-ActiveOnly {
  visibility: hidden;
}

.aa-PanelHeader {
  background: #3e34d3;
  background: rgba(var(--aa-primary-color-rgb), 1);
  color: #fff;
  height: var(--aa-modal-header-height);
  padding: 8px 16px;
  padding: var(--aa-spacing-half) var(--aa-spacing);
  align-items: center;
  margin: 0;
  display: grid;
  position: relative;
}

.aa-PanelHeader:after {
  background-image: linear-gradient(#fff, #fff0);
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 1), rgba(var(--aa-background-color-rgb), 0));
  bottom: -8px;
  bottom: calc(var(--aa-spacing-half) * -1);
  content: "";
  height: 8px;
  height: var(--aa-spacing-half);
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
}

.aa-PanelFooter, .aa-PanelHeader:after {
  z-index: 9999;
  z-index: var(--aa-base-z-index);
}

.aa-PanelFooter {
  background-color: #fff;
  background-color: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  box-shadow: inset 0 1px #807ea34d;
  box-shadow: inset 0 1px 0 rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
  padding: 16px;
  padding: var(--aa-spacing);
  justify-content: space-between;
  margin: 0;
  display: flex;
  position: relative;
}

.aa-PanelFooter:after {
  background-image: linear-gradient(#fff0, #807ea399);
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 0), rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha)));
  content: "";
  height: 16px;
  height: var(--aa-spacing);
  opacity: .12;
  pointer-events: none;
  top: -16px;
  left: 0;
  right: 0;
  top: calc(var(--aa-spacing) * -1);
  z-index: 9998;
  z-index: calc(var(--aa-base-z-index)  - 1);
  position: absolute;
}

.aa-DetachedContainer {
  background: #fff;
  background: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  box-shadow: 0 0 0 1px #23263b1a, 0 6px 16px -4px #23263b26;
  box-shadow: var(--aa-panel-shadow);
  z-index: 9999;
  z-index: var(--aa-base-z-index);
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.aa-DetachedContainer:after {
  height: 32px;
}

.aa-DetachedContainer .aa-SourceHeader {
  margin: 8px 0 8px 2px;
  margin: var(--aa-spacing-half) 0 var(--aa-spacing-half) 2px;
}

.aa-DetachedContainer .aa-Panel {
  background-color: #fff;
  background-color: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  box-shadow: none;
  border-radius: 0;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  position: relative;
}

.aa-DetachedContainer .aa-PanelLayout {
  box-shadow: none;
  max-height: none;
  width: 100%;
  margin: 0;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.aa-DetachedFormContainer {
  border-bottom: 1px solid #807ea34d;
  border-bottom: 1px solid rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
  padding: 8px;
  padding: var(--aa-spacing-half);
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  display: flex;
}

.aa-DetachedCancelButton {
  color: inherit;
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
  cursor: pointer;
  font: inherit;
  margin: 0 0 0 8px;
  margin: 0 0 0 var(--aa-spacing-half);
  padding: 0 8px;
  padding: 0 var(--aa-spacing-half);
  background: none;
  border: 0;
  border-radius: 3px;
}

.aa-DetachedCancelButton:focus, .aa-DetachedCancelButton:hover {
  box-shadow: inset 0 0 0 1px #807ea34d;
  box-shadow: inset 0 0 0 1px rgba(var(--aa-panel-border-color-rgb), var(--aa-panel-border-color-alpha));
}

.aa-DetachedContainer--modal {
  bottom: inherit;
  height: auto;
  max-width: 680px;
  max-width: var(--aa-detached-modal-max-width);
  border-radius: 6px;
  margin: 0 auto;
  position: absolute;
  top: 3%;
}

.aa-DetachedContainer--modal .aa-PanelLayout {
  max-height: 500px;
  max-height: var(--aa-detached-modal-max-height);
  padding-bottom: 8px;
  padding-bottom: var(--aa-spacing-half);
  position: static;
}

.aa-DetachedSearchButton {
  background-color: #fff;
  background-color: rgba(var(--aa-input-background-color-rgb), var(--aa-input-background-color-alpha));
  border: 1px solid #807ea3cc;
  border: 1px solid rgba(var(--aa-input-border-color-rgb), var(--aa-input-border-color-alpha));
  color: #807ea399;
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  cursor: pointer;
  font: inherit;
  font-family: inherit;
  font-family: var(--aa-font-family);
  font-size: 16px;
  font-size: var(--aa-font-size);
  height: 44px;
  height: var(--aa-search-input-height);
  padding: 0 5.5px;
  padding: 0 calc(var(--aa-search-input-height) / 8);
  text-align: left;
  width: 100%;
  border-radius: 3px;
  align-items: center;
  margin: 0;
  display: flex;
  position: relative;
}

.aa-DetachedSearchButton:focus {
  border-color: #3e34d3;
  border-color: rgba(var(--aa-primary-color-rgb), 1);
  box-shadow: 0 0 0 3px #3e34d333, inset 0 0 0 2px #3e34d333;
  box-shadow: rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 3px, inset rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha)) 0 0 0 2px;
  outline: none;
}

.aa-DetachedSearchButtonIcon {
  color: #3e34d3;
  color: rgba(var(--aa-primary-color-rgb), 1);
  cursor: auto;
  height: 100%;
  width: 36px;
  width: calc(var(--aa-icon-size)  + var(--aa-spacing));
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.aa-DetachedSearchButtonQuery {
  color: #262627;
  color: rgba(var(--aa-text-color-rgb), 1);
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.25em;
  overflow: hidden;
}

.aa-DetachedSearchButtonPlaceholder[hidden] {
  display: none;
}

.aa-Detached {
  height: 100vh;
  overflow: hidden;
}

.aa-DetachedOverlay {
  background-color: #73728166;
  background-color: rgba(var(--aa-overlay-color-rgb), var(--aa-overlay-color-alpha));
  height: 100vh;
  z-index: 9998;
  z-index: calc(var(--aa-base-z-index)  - 1);
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.aa-GradientBottom, .aa-GradientTop {
  height: 8px;
  height: var(--aa-spacing-half);
  pointer-events: none;
  z-index: 9999;
  z-index: var(--aa-base-z-index);
  position: absolute;
  left: 0;
  right: 0;
}

.aa-GradientTop {
  background-image: linear-gradient(#fff, #fff0);
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 1), rgba(var(--aa-background-color-rgb), 0));
  top: 0;
}

.aa-GradientBottom {
  background-image: linear-gradient(#fff0, #fff);
  background-image: linear-gradient(rgba(var(--aa-background-color-rgb), 0), rgba(var(--aa-background-color-rgb), 1));
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: calc(var(--aa-spacing) / 4);
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: calc(var(--aa-spacing) / 4);
  bottom: 0;
}

@media (hover: none) and (pointer: coarse) {
  .aa-DesktopOnly {
    display: none;
  }
}

@media (hover: hover) {
  .aa-TouchOnly {
    display: none;
  }
}

/*# sourceMappingURL=algolia-search.css.map */
